<script setup>
import { useAppStore } from '@/stores/App'
const nuxtApp = useNuxtApp()
const app = useAppStore()
const staticURLAdimg = useRuntimeConfig().public.staticURLAdimg
const staticURLImg = useRuntimeConfig().public.staticURLImg

const props = defineProps({
    spot: {
        type: String,
        default: null,
    },
    page: {
        type: String,
        default: null,
    },
    placing: {
        type: Number,
        default: null,
    },
    status: {
        type: String,
        default: 'Paid',
    },
    position: {
        type: String,
        default: null,
    },
})

const { data, status, error } = await useLazyAsyncData(
    `${props.spot}-${props.page}-${props.position}`,
    () => $fetch(`/api/campaign/bookings?spot=${props.spot}&page=${props.page}&status=${props.status}`),
    {
        transform: (data) => {
            return {
                sidebarData: data.bookings?.edges.map((edge) => edge.node),
                // totalCount: data.bookings.totalCount,
                fetchedAt: new Date(Date.now()),
            }
        },
        getCachedData(key) {
            const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key]

            if (!data) {
                return
            }

            const expirationDate = new Date(data.fetchedAt)
            expirationDate.setTime(expirationDate.getTime() + 60 * 60000) // cache set to 1 hour
            const isExpired = expirationDate.getTime() < Date.now()
            if (isExpired) {
                return
            }

            return data
        },
    }
)

watchEffect(() => {
    if (error.value) {
        throw createError({
            statusCode: 500,
            message: error.value,
            fatal: false,
        })
    }
})

function imgSources(v) {
    // console.log(JSON.parse(v) ? JSON.parse(v).imgSources : null)
    return JSON.parse(v) ? JSON.parse(v).imgSources : null
}

// Check if there are any bookings available
const totalBookings = computed(() => {
    return data.value ? data.value.sidebarData.length : 0
})

// Calculate how many blank banners to show
const count = computed(() => {
    let count = 0
    // console.log('totalCount = ', totalBookings.value)
    switch (totalBookings.value) {
        case 0:
            count = 2
            break
        case 1:
            count = 1
            break
        default:
            break
    }
    // console.log('count = ', count)
    return count
})
</script>

<template>
    <v-row id="adsidebar">
        <v-col v-if="status === 'pending' || status === 'idle'"><app-loader /></v-col>
        <template v-else>
            <v-col v-for="item in data.sidebarData" :key="item.campaign.title" :cols="props.placing" class="d-flex">
                <v-img
                    id="cardImg"
                    :src="
                        item.campaign.isInternal ? staticURLImg + item.campaign.img : staticURLAdimg + item.campaign.img
                    "
                    :alt="item.campaign.title"
                    class="rounded st-box-shadow"
                    cover
                >
                    <template #sources>
                        <source
                            v-for="imgSrc in imgSources(item.campaign.content)"
                            :key="imgSrc.id"
                            :media="imgSrc.media"
                            :srcset="staticURLAdimg + imgSrc.img"
                        />
                    </template>
                    <nuxt-link
                        :id="item.campaign.urlId"
                        :data-adspot="`${item.spot.name}-${item.spot.page.name}`"
                        :data-adbrand="item.brand.name"
                        :data-adtitle="item.campaign.title"
                        :to="item.campaign.url"
                        :target="item.campaign.isInternal ? undefined : `_blank`"
                        :class="`text-decoration-none text-grey-darken-4 ${item.campaign.gtag}`"
                        :aria-label="`Explore ${item.campaign.title}`"
                        rel="noopener"
                    >
                        <v-container class="fill-height align-start"> </v-container>
                    </nuxt-link>
                    <img
                        v-if="item.campaign.trkngPxl"
                        :style="`${item.campaign.trkngPxlStyle}`"
                        :src="item.campaign.trkngPxl"
                        :width="item.campaign.trkngPxlWidth"
                        :height="item.campaign.trkngPxlHeight"
                        :alt="item.campaign.title"
                        border="0"
                    />
                </v-img>
            </v-col>
        </template>
        <template v-if="!(status === 'pending' || status === 'idle') && count > 0">
            <v-col v-for="n in count" :key="n" :cols="props.placing" class="d-flex">
                <v-img
                    id="cardImg"
                    :src="
                        props.page == 'deals' ? '/wbst-sidebar-deals-blank-md.webp' : '/wbst-carousel-home-blank.webp'
                    "
                    alt="Spot open for booking"
                    class="rounded st-box-shadow"
                    cover
                >
                    <template v-if="props.page == 'deals'" #sources>
                        <source
                            v-for="imgSrc in app.noSidebarDealAds.imgSources"
                            :key="imgSrc.id"
                            :media="imgSrc.media"
                            :srcset="`/${imgSrc.img}`"
                        />
                    </template>
                    <nuxt-link
                        :data-adspot="`${props.spot}-${props.page}`"
                        data-adbrand="None"
                        data-adtitle="Spot open for booking"
                        to="support"
                        class="text-decoration-none text-grey-darken-4 bnrclk"
                        aria-label="Contact us to book this spot"
                    >
                        <v-container class="fill-height align-start"> </v-container>
                    </nuxt-link>
                </v-img>
            </v-col>
        </template>
    </v-row>
</template>

<style scoped></style>
